<template>
  <b-overlay :show="false" rounded="sm" variant="white">
    <b-card>
      <global-ledger-accounts-table
        :accounts="mappedAccounts"
        :loading="loading"
        show-button
        show-search
        @button-click="onAddAccountClicked"
        @edit-account-click="onEditAccountClicked"
      />

      <!-- ADD LEDGER ACCOUNT MODAL -->
      <b-modal
        :id="'add-global-ledger-account-modal'"
        title="Afegeix un compte comptable"
        scrollable
        hide-footer
        size="lg"
      >
        <global-ledger-account-form
          @cancel="onAddAccountCancel"
          @account-added="onAccountAdded"
          @add-account-error="onAddAccountError"
        />
      </b-modal>

      <!-- EDIT LEDGER ACCOUNT MODAL -->
      <b-modal
        :id="'edit-global-ledger-account-modal'"
        title="Edita el compte comptable"
        scrollable
        hide-footer
      >
        <global-ledger-account-form
          :account="selectedAccount"
          @cancel="onEditAccountCancel"
          @account-edited="onAccountEdited"
          @edit-account-error="onEditAccountError"
          @account-deleted="onAccountDeleted"
          @delete-account-error="onDeleteAccountError"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BModal } from "bootstrap-vue";
import GlobalLedgerAccountsTable from "@/views/accounting/configuration/global-ledger-accounts/components/GlobalLedgerAccountsTable.vue";
import GlobalLedgerAccountForm from "@/views/accounting/configuration/global-ledger-accounts/components/GlobalLedgerAccountForm.vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BCard,
    GlobalLedgerAccountsTable,
    GlobalLedgerAccountForm,
    BModal,
  },
  data() {
    return {
      selectedAccount: null,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["ledgerAccounts/loading"];
    },
    ledgerAccounts() {
      return this.$store.getters["ledgerAccounts/accounts"];
    },
    mappedAccounts() {
      if (!this.ledgerAccounts.length) {
        return [];
      }

      return this.ledgerAccounts.map((account) => ({
        uuid: account.uuid || this.$t("No definit"),
        name: account.name || this.$t("No definit"),
        code: account.code || this.$t("No definit"),
        number: account.number || this.$t("No definit"),
        description: account.description || this.$t("No definit"),
      }));
    },
  },
  created() {
    if (!this.loading) this.fetchLedgerAccounts();
  },
  methods: {
    fetchLedgerAccounts() {
      this.$store
        .dispatch("ledgerAccounts/fetchAccounts")
        .catch(() =>
          notifyError(
            this.$t("errors.fetchLedgerAccounts.title"),
            this.$t("errors.fetchLedgerAccounts.description")
          )
        );
    },
    onAddAccountClicked() {
      this.$bvModal.show("add-global-ledger-account-modal");
    },
    onAddAccountCancel() {
      this.$bvModal.hide("add-global-ledger-account-modal");
    },
    onAccountAdded() {
      // this.fetchLedgerAccounts();
      this.$bvModal.hide("add-global-ledger-account-modal");
      notifySuccess("Servei afegit", "El servei ha estat afegit correctament");
    },
    onAddAccountError() {
      notifyError("Error", "Hi ha hagut un error al intentar afegir el servei");
    },
    onEditAccountCancel() {
      this.$bvModal.hide("edit-global-ledger-account-modal");
    },
    onAccountEdited() {
      // this.fetchLedgerAccounts();
      this.$bvModal.hide("edit-global-ledger-account-modal");
      notifySuccess("Servei editat", "El servei ha estat editat correctament");
    },
    onEditAccountError() {
      notifyError("Error", "Hi ha hagut un error al intentar editar el servei");
    },
    onAccountDeleted() {
      // this.fetchLedgerAccounts();
      notifySuccess(
        "Servei eliminat",
        "El servei ha estat eliminat correctament"
      );
    },
    onDeleteAccountError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar eliminar el servei"
      );
    },
    onEditAccountClicked(accountUuid) {
      this.selectedAccount = this.ledgerAccounts.find(
        (account) => account.uuid === accountUuid
      );
      this.$bvModal.show("edit-global-ledger-account-modal");
    },
  },
};
</script>
