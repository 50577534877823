<template>
  <div id="global-service-form">
    <!-- FORM -->
    <b-row class="mb-2">
      <b-col v-if="!account" cols="12" class="mb-1">
        <b-row>
          <b-col cols="12">
            <h6>Tipus de compte</h6>
          </b-col>
          <b-col cols="6">
            <fr-form-select
              v-model="accountOtherLedgerCode"
              :options="otherLedgerCodeOptions"
              placeholder="Codis generals"
            />
          </b-col>
          <b-col cols="6">
            <fr-form-select
              v-model="accountOtaCode"
              :options="otaOptions"
              placeholder="Codis de portal"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="mb-1">
        <fr-form-ledger-account-input
          v-model="accountNumber"
          label="Nº de compte"
          placeholder="p. e. 572.1"
        />
      </b-col>
      <b-col cols="12">
        <fr-form-input
          v-model="accountDescription"
          placeholder="p. e. Descripció interna"
        >
          Descripció <small>(opcional)</small>
        </fr-form-input>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col v-if="account" cols="6" sm="auto">
        <b-button block variant="light" @click="confirmDelete">
          Elimina
        </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col :cols="account ? 12 : 6" sm="auto">
        <b-button block variant="primary" @click="save"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import {
  FrFormInput,
  FrFormSelect,
  FrFormLedgerAccountInput,
} from "@/@foravila-core/components/fr-form";
import { otaOptions } from "@/utils/select-options";

export default {
  components: {
    BRow,
    BCol,
    FrFormInput,
    FrFormLedgerAccountInput,
    FrFormSelect,
    BButton,
  },
  props: {
    account: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      accountNumber: null,
      accountOtaCode: null,
      accountOtherLedgerCode: null,
      accountDescription: null,
      otaOptions,
    };
  },
  computed: {
    otherLedgerCodeOptions() {
      return [
        {
          label: "Compte de Bancs",
          value: "BANK",
        },
        {
          label: "Compte d'IVA",
          value: "VAT",
        },
        {
          label: "Compte d'IVA (subarrendament)",
          value: "VAT_SUBURANCE",
        },
        {
          label: "Compte de Vendes",
          value: "SALES",
        },
        {
          label: "Compte de Vendes (subarrendament)",
          value: "SALES_SUBURANCE",
        },
      ];
    },
    accountCode() {
      return this.accountOtaCode || this.accountOtherLedgerCode || null;
    },
  },
  created() {
    this.initForm();
  },
  watch: {
    accountOtaCode(value) {
      if (value) {
        this.accountOtherLedgerCode = null;
      }
    },
    accountOtherLedgerCode(value) {
      if (value) {
        this.accountOtaCode = null;
      }
    },
  },
  methods: {
    initForm() {
      const accountCode = this.account?.code || null;

      this.accountNumber = this.account?.number || null;
      this.accountOtaCode = this.foundInOptions(otaOptions, accountCode)
        ? accountCode
        : null;
      this.accountOtherLedgerCode = this.foundInOptions(
        this.otherLedgerCodeOptions,
        accountCode
      )
        ? accountCode
        : null;
      this.accountDescription = this.account?.description || null;
    },
    resetForm() {
      this.accountNumber = null;
      this.accountOtaCode = null;
      this.accountOtherLedgerCode = null;
      this.accountDescription = null;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      if (this.account) {
        this.updateAccount();
      } else {
        this.createAccount();
      }
    },
    createAccount() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("ledgerAccounts/addAccount", {
          number: this.accountNumber,
          code: this.accountCode,
          description: this.accountDescription,
        })
        .then(() => this.$emit("account-added"))
        .catch(() => {
          this.$emit("add-account-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    updateAccount() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("ledgerAccounts/updateAccount", {
          uuid: this.account.uuid,
          number: this.accountNumber,
          code: this.accountCode,
          description: this.accountDescription,
        })
        .then(() => this.$emit("account-edited"))
        .catch(() => {
          this.$emit("edit-account-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    confirmDelete() {
      this.$swal({
        title: "Eliminar compte",
        text: "Estàs a punt d'eliminar aquest compte comptable, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteAccount();
        }
      });
    },
    deleteAccount() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("ledgerAccounts/deleteAccount", this.account.uuid)
          .then(() => this.$emit("account-deleted"))
          .catch(() => {
            this.$emit("delete-account-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    foundInOptions(options, code) {
      return options.some((option) => option.value === code);
    },
  },
};
</script>
